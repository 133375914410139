import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import { Grid, GridCell } from '../Grid';
import TrendsHeader from './TrendsHeader';
import { Breadcrumbs } from '../Breadcrumb';

const frontpagePropertyTypes = {
  intro: PropTypes.string,
  description: PropTypes.string,
  specialLinks: PropTypes.node,
  mainSection: PropTypes.node,
  aboutSection: PropTypes.node,
  breadcrumbs: PropTypes.arrayOf(PropTypes.node).isRequired,
};

type FrontpageProperties = InferProps<typeof frontpagePropertyTypes>;

const Frontpage = ({
  intro,
  description,
  specialLinks,
  mainSection,
  aboutSection,
  breadcrumbs,
}: FrontpageProperties) => (
  <Grid>
    <GridCell padding="0 20px">
      <Breadcrumbs
        crumbs={breadcrumbs}
      />
    </GridCell>
    <TrendsHeader
      title={intro}
      description={description}
      specialLinks={specialLinks}
    />
    <GridCell padding="16px">
      {mainSection}
    </GridCell>
    {aboutSection && (
      <GridCell padding="16px">
        {aboutSection}
      </GridCell>
    )}
  </Grid>
);

Frontpage.propTypes = frontpagePropertyTypes;

export default Frontpage;
