import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import styled from '@emotion/styled';
import { Theme } from '@emotion/react';
import Headline3 from '../Typography/Heading/Headline3';
import { GridCell } from '../Grid';
import breakpoints from '../Theme/breakpoints';

const FullHeightGridCell = styled(GridCell)`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
  min-height: calc(100vh - 145px);

  @media (min-width: ${breakpoints.sm}px) {
    display: block;
    min-height: auto;
  }
`;

const MainTitle = styled(Headline3)`
  && {
    width: 100%;
    text-align: center;
    display: block;
  }
`;

const StyledSpan = styled('span')<Theme>`
  && {
    display: block;
    padding: 16px 0;

    ${({ theme }) => theme.typography?.body1};
  }
`;

const trendsHeaderPropertyTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  specialLinks: PropTypes.node,
};

type TrendsHeaderProperties = InferProps<typeof trendsHeaderPropertyTypes>;

const TrendsHeader = (
  { title, description, specialLinks }: TrendsHeaderProperties,
) => (
  <FullHeightGridCell padding="24px 16px 8px">
    <MainTitle tag="h1">
      {title}
      {description && (
        <StyledSpan>
          {' '}
          {description}
        </StyledSpan>
      )}
    </MainTitle>
    {specialLinks}
  </FullHeightGridCell>
);

TrendsHeader.propTypes = trendsHeaderPropertyTypes;

export default TrendsHeader;
